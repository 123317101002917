import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LoadingComponent } from '../loading/loading.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-secondary-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, LoadingComponent, MatRippleModule],
  templateUrl: './secondary-button.component.html',
  styleUrl: './secondary-button.component.css',
})
export class SecondaryButtonComponent {
  @Input({ required: true }) text: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() leadingIcon: string;
  @Input() trailingIcon: string;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  handleClick(): void {
    this.buttonClicked.emit();
  }
}
