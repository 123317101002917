export enum Status {
  UNATTEMPTED = 'Unattempted',
  STARTED = 'Started',
  COMPLETED = 'Completed',
}

export enum LearningPlanStatus {
  SCHEDULED = 'Scheduled',
  ACTIVE = 'Active',
}

export class CreateLearningPlanInstanceResponseDTO {
  learningPlanInstance: {
    id: string;
  };
}

export class LearningPlanInstanceDTO {
  id: string;
  name: string;
  createdAt: string;
  startAt: string;
  topicArea: TypeTopicArea;
}

export interface TypeTopicArea {
  id: string;
  name: string;
}

export interface TypeTopicAreasResponse {
  topicAreas: TypeTopicArea[];
}

export interface TypePhase {
  name: string;
  duration: string;
  courses: string[];
}

export interface TypeLearningPlanInstancePOST {
  learningPlanInstance: {
    name: string;
    topicAreaId: string;
    startDate: string;
    looping: boolean;
    phases: TypePhase[];
  };
}

export class LearningPlanInstancesResponseDTO {
  learningPlanInstances: LearningPlanInstanceDTO[];
}

export class EnrolledStudentDTO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phase?: {
    name: string;
    courseInstance: {
      id: string;
      status: Status;
    };
  };
}
export class LearningPlanInstanceEnrollmentsResponseDTO {
  students: EnrolledStudentDTO[];
}

export class Phase {
  id: string;
  name: string;
  duration: string;
  nextPhaseId: string | null;
  start: boolean;
  courseInstances: Array<{
    id: string;
    courseId: string;
    name: string;
    numberOfEnrolledStudents: number;
  }>;
}

export class LearningPlanInstanceDetailDTO extends LearningPlanInstanceDTO {
  numberOfEnrolledStudents: number;
  numberOfEnrolledStudentsLearningPlanStarted: number;
  numberOfCompletedStudents: number;
  phases: Phase[];
  status: LearningPlanStatus;
}

export class LearningPlanInstanceDetailResponseDTO {
  learningPlanInstance: LearningPlanInstanceDetailDTO;
}
export class LearningPlanEnrollmentRequestDTO {
  students: Array<{ id: string }>;
}

export class LearningPlanUnenrollmentRequestDTO {
  students: Array<{ id: string }>;
}

export class SendReminderRequestDTO {
  students: Array<{ id: string; courseInstanceId: string }>;
}

export class CourseGalleryItemDTO {
  id: string;
  externalMoodleId: string;
  name: string;
  description: string;
  aboutUrl: string;
}
