import {
  EnrolledStudentDTO,
  LearningPlanEnrollmentRequestDTO,
  LearningPlanInstanceDetailDTO,
  LearningPlanUnenrollmentRequestDTO,
  Phase,
  SendReminderRequestDTO,
} from '../models/LearningPlanDTO';
import { LearningPlanEnrolledStudentUIModel } from '../models/LearningPlanEnrolledStudentUIModel';
import { LearningPlanInstanceDetailUiModel } from '../models/LearningPlanInstanceDetailUIModel';

export function toLearningPlanEnrollmentRequestDTO(students: Array<{ id: string }>): LearningPlanEnrollmentRequestDTO {
  const learningPlanEnrollmentRequestDTO = new LearningPlanEnrollmentRequestDTO();
  learningPlanEnrollmentRequestDTO.students = students;
  return learningPlanEnrollmentRequestDTO;
}

export function toLearningPlanUnenrollmentRequestDTO(
  students: LearningPlanEnrolledStudentUIModel[],
): LearningPlanUnenrollmentRequestDTO {
  const learningPlanUnenrollmentRequestDTO = new LearningPlanUnenrollmentRequestDTO();
  learningPlanUnenrollmentRequestDTO.students = students.map((student) => ({ id: student.id }));
  return learningPlanUnenrollmentRequestDTO;
}

export function toLearningPlanEnrolledStudentUIModel(
  students: EnrolledStudentDTO[],
): LearningPlanEnrolledStudentUIModel[] {
  return students.map((student) => {
    const username = `${student.firstName} ${student.lastName}`;
    const { email, id } = student;
    return {
      id,
      username,
      email,
      currentPhase: student.phase?.name ?? '-',
      status: student.phase?.courseInstance.status ?? 'NotStarted',
      courseInstanceId: student.phase?.courseInstance.id ?? '',
    };
  });
}

export function toSendReminderRequestDTO(students: LearningPlanEnrolledStudentUIModel[]): SendReminderRequestDTO {
  const reminderRequestDTO = new SendReminderRequestDTO();
  reminderRequestDTO.students = students.map((student) => ({
    id: student.id,
    courseInstanceId: student.courseInstanceId,
  }));
  return reminderRequestDTO;
}

function orderPhases(phases: Phase[]): Phase[] {
  const startPhase = phases.find((phase) => phase.start);
  if (!startPhase) {
    throw new Error('No starting phase found');
  }

  const orderedPhases: Phase[] = [];
  const visitedPhases: Set<string> = new Set();
  let currentPhase: Phase | undefined = startPhase;

  while (currentPhase) {
    if (visitedPhases.has(currentPhase.id)) {
      break;
    }

    orderedPhases.push(currentPhase);
    visitedPhases.add(currentPhase.id);

    let nextPhase = undefined;
    for (const phase of phases) {
      if (phase.id === currentPhase.nextPhaseId) {
        nextPhase = phase;
        break;
      }
    }
    currentPhase = nextPhase;
  }

  return orderedPhases;
}

export function toLearningPlanInstanceDetailUiModel(
  learningPlanInstanceDto: LearningPlanInstanceDetailDTO,
): LearningPlanInstanceDetailUiModel {
  const orderedPhases = orderPhases(learningPlanInstanceDto.phases);

  return {
    id: learningPlanInstanceDto.id,
    name: learningPlanInstanceDto.name,
    createdAt: learningPlanInstanceDto.createdAt,
    startAt: learningPlanInstanceDto.startAt,
    numberOfEnrolledStudents: learningPlanInstanceDto.numberOfEnrolledStudents,
    numberOfEnrolledStudentsLearningPlanStarted: learningPlanInstanceDto.numberOfEnrolledStudentsLearningPlanStarted,
    numberOfCompletedStudents: learningPlanInstanceDto.numberOfCompletedStudents,
    orderedPhases: orderedPhases,
    topicArea: learningPlanInstanceDto.topicArea,
    status: learningPlanInstanceDto.status,
  };
}
