<button
  type="button"
  [disabled]="disabled"
  [ngClass]="{
    'w-full px-4 py-2 rounded-lg border border-grey-midGrey items-center justify-center inline-flex': true,
    'bg-grey-midGrey': disabled,
    'bg-white': !disabled,
  }"
  (click)="handleClick()"
  matRipple
  [matRippleDisabled]="disabled"
>
  @if (loading) {
    <span class="size-6 items-center justify-center">
      <app-loading></app-loading>
    </span>
  } @else {
    @if (leadingIcon) {
      <mat-icon class="material-icons-outlined mr-2 text-grey">{{
        leadingIcon
      }}</mat-icon>
    }
    <span class="self-center text-base font-medium text-primary">{{
      text
    }}</span>
    @if (trailingIcon) {
      <mat-icon class="material-icons-outlined ml-2 text-primary">{{
        trailingIcon
      }}</mat-icon>
    }
  }
</button>
